import banquet_hall from "./image/amenities/banquet_hall.svg";
import bar from "./image/amenities/bar.svg";
import bathtub from "./image/amenities/bathtub.svg";
import breakfast from "./image/amenities/breakfast.svg";
import CCTV from "./image/amenities/CCTV.svg";
import credit_debit from "./image/amenities/credit_debit.svg";
import dining_area from "./image/amenities/dining_area.svg";
import elevator from "./image/amenities/Elevator.svg";
import fridge from "./image/amenities/fridge.svg";
import Geyser from "./image/amenities/Geyser.svg";
import Jacuzzi from "./image/amenities/Jacuzzi.svg";
import Laundry from "./image/amenities/Laundry.svg";
import Parking from "./image/amenities/Parking.svg";
import Pet from "./image/amenities/Pet.svg";
import power_backup from "./image/amenities/power_backup.svg";
import restaurant from "./image/amenities/restaurant.svg";
import spa from "./image/amenities/spa.svg";
import Swimming from "./image/amenities/Swimming.svg";
import TV from "./image/amenities/TV.svg";
import Water from "./image/amenities/Water.svg";
import wheelchairs from "./image/amenities/wheelchair.svg";
import Wifi from "./image/amenities/Wifi.svg";
import gym from "./image/amenities/gym.svg";
import AC from "./image/amenities/AC.svg";
import Gold from "./image/amenities/Gold.png";
import Bronze from "./image/amenities/Bronze.png";
import Silver from "./image/amenities/Silver.png";

import ExtranetLogo from "./image/header/extranet logo.svg";
import profileOpen from "./image/header/Profile Open.svg";
import profileUnopen from "./image/header/Profile Unopen.svg";

import home from "./image/sidemenu/Hotel summary.svg";
import bookings from "./image/sidemenu/Bookings.svg";
import amenities from "./image/sidemenu/Amenities.svg";
import admin from "./image/sidemenu/Admin.svg";
import hotelFinance from "./image/sidemenu/Hotel Finance.svg";
import hotelPolicies from "./image/sidemenu/New Policy.svg";
import hotelProfile from "./image/sidemenu/New  Hotel Profile.svg";
import manageRooms from "./image/sidemenu/Manage Rooms.svg";
import staff from "./image/sidemenu/Staff.svg";
import property from "./image/sidemenu/My properties.svg";

import closePurple from "./image/other/close purple.svg";
import star from "./image/other/Stars.svg";
import closeBlack from "./image/other/close black.svg";
import save from "./image/other/save.svg";

import bookingHistoryIcon from "./image/bookings/booking history.svg";
import upcomingBookingsIcon from "./image/bookings/upcoming booking.svg";
import greenArrow from "./image/finance/arrowGreen.svg";
import redArrow from "./image/finance/arrowRed.svg";

// import downloadIcon from "./image/other/download.jpg";
import saved from "./image/other/saved.svg";
import editRooms from "./image/other/edit rooms.svg";
import viewRooms from "./image/other/View.svg";

import rupeeIcon from "./image/finance/₹.svg";
import arrowIcon from "./image/finance/Arrow 1.svg";
import notesIcon from "./image/finance/c&d notes.svg";
import calenderIcon from "./image/finance/calender.svg";
import ledgerIcon from "./image/finance/Ledger.svg";
import invoiceIcon from "./image/finance/invoice.svg";
import salesIcon from "./image/finance/sales.svg";
import summary from "./image/finance/summary.svg";
import checkAll from "./image/finance/check-all.svg";
import Stars from "./image/general/Stars.svg";
import available_rooms from "./image/general/available_rooms.svg";
import extranet_logo from "./image/general/extranet_logo.svg";
import sold_out_Rooms from "./image/general/sold_out_Rooms.svg";
import New_Hotel_Profile from "./image/general/New_Hotel_Profile.svg";
import New_Hotel_Profile_blue from "./image/general/New_Hotel_Profile_blue.svg";
import closeRedIcon from "./image/other/close red.svg";
import verifiedIcon from "./image/other/Verified icon.svg";
import close from "./image/general/close.svg"; 
import hotelImage from "./image/other/img1.jpg";
import downloadIcon from "./image/bookings/Download icon.svg";

import active from "./image/profile/active.svg";
import linked from "./image/profile/Linked.svg";
import luxury from "./image/profile/Luxury.svg";
import newIcon from "./image/profile/New.svg";
import Premium from "./image/profile/Premium.svg";
import noReview from "./image/profile/No review illustration.png";
import unlinked from "./image/profile/Unlinked.svg";
import inactive from "./image/profile/Inactive.svg";
import notification from "./image/home/Notification.svg";
import commision from "./image/home/commision.svg";
import total_sales from "./image/home/total_sales.svg";
import error from "./image/home/error.svg";
import up from "./image/home/up.svg";
import up_black from "./image/home/up_black.svg";
import down_black from "./image/home/down_black.svg";
import down from "./image/home/down.svg";
import navbar_m from "./image/home/Mob navigation.png";
import checkInGuide from "./image/home/Mob Bookings check-in.png";
import bookingsTab from "./image/home/Mob Bookings tab.png";
import bookingsSearch from "./image/home/Mob Bookingssearch.png";
import bulkButton from "./image/home/Mob Bulk Update Button.png";
import bulkUpdateDates from "./image/home/Mob Bulk update dates.png";
import editGuide from "./image/home/Mob edit inventory.png";
import financeGuide from "./image/home/Mob Finances.png";
import inventoryDatesGuide from "./image/home/Mob inventory Dates.png";
import manageCheckInGuide from "./image/home/Mob manage check-in.png";
import booking_m from "./image/home/bookings.svg";
import finances_m from "./image/home/finances.svg";
import right from "./image/home/right.svg";
import left from "./image/home/left.svg";
import greenup from "./image/home/green_arrow.svg";
import summary_m from "./image/home/summary.svg";
import rooms_m from "./image/home/rooms.svg";
import Blocked_Icon from "./image/home/Blocked_Icon.svg";
import Soldout_Icon from "./image/home/Soldout_Icon.svg";
import profile_m from "./image/home/profile.svg";
import left_black from "./image/home/left_black.svg";
import right_black from "./image/home/right_black.svg";
import transferredIn from "./image/bookings/Transferred in.svg";
import transferredOut from "./image/bookings/Transferred out.svg";
import Edit from "./image/other/edit.svg";
import downArrow from "./image/sidemenu/Down arrow.svg";
import upArrow from "./image/sidemenu/Up arrow.svg";
import editDraft from "./image/property/edit draft.svg";
import deleteDraft from "./image/property/delete draft.svg";
import tipIcon from "./image/other/Tip.svg";
import totalRoomsSold from "./image/home/Total Rooms Sold.svg";
import totalRooms from "./image/home/Total Rooms.svg";
import customerCare from "./image/home/Customer care illustration.png";
import noBooking from "./image/home/No bbokings illustration.png";
import Loader from "./image/lottie/Loader.json";
import accessDenied from "./image/newUser/Access Denied.png";
import bulkUpdate from "./image/newUser/Bulk Update.png";
import hotelSummary from "./image/newUser/Hotel Summary.png";
import financeDashboard from "./image/newUser/finance Dashboard.png";
import newPagehotelProfile from "./image/newUser/Hotel Profile.png";
import increaseRevenue from "./image/newUser/Increase Revenue.png";
import inventory from "./image/newUser/Inventory.png";
import manageRates from "./image/newUser/manage rates.png";
import filter from "./image/finance/filter_list.svg";
import hsassitIcon from "./image/helpNSupport/Brevistay Assist Desktop.png";
import hscontactUs from "./image/helpNSupport/Contact.png";
import hsgettingStarted from "./image/helpNSupport/getting started.png";
import hslimitedAccess from "./image/helpNSupport/Limited access.png";
import hsmailUs from "./image/helpNSupport/Mail us.png";
import hsmanageRates from "./image/helpNSupport/manage rates.png";
import hsFAQs from "./image/helpNSupport/FAQs.png";
import Notification from "./image/general/Notification.svg";
import Total_Rooms from "./image/general/Total_Rooms.svg";
import Total_Rooms_Sold from "./image/general/Total_Rooms_Sold.svg";
import am_banquet_hall from "./image/amenities/banquet_hall.svg";
import am_bar from "./image/amenities/bar.svg";
import am_bathtub from "./image/amenities/bathtub.svg";
import am_breakfast from "./image/amenities/breakfast.svg";
import am_public_cctv from "./image/amenities/CCTV.svg";
import conference_room from "./image/amenities/conference_room.svg";
import am_cards_accepted from "./image/amenities/credit_debit.svg";
import am_dining_area from "./image/amenities/dining_area.svg";
import am_elevator from "./image/amenities/Elevator.svg";
import am_fridge from "./image/amenities/fridge.svg";
import am_geyser from "./image/amenities/Geyser.svg";
import am_jacuzzi from "./image/amenities/Jacuzzi.svg";
import am_laundry from "./image/amenities/Laundry.svg";
import am_parking from "./image/amenities/Parking.svg";
import am_power_backup from "./image/amenities/power_backup.svg";
import am_house_restaurant from "./image/amenities/restaurant.svg";
import am_spa from "./image/amenities/spa.svg";
import am_swimming from "./image/amenities/Swimming.svg";
import am_tv from "./image/amenities/TV.svg";
import am_water from "./image/amenities/Water.svg";
import am_wheelchair from "./image/amenities/wheelchair.svg";
import am_wifi from "./image/amenities/Wifi.svg";
import am_gym from "./image/amenities/gym.svg";
import am_ac from "./image/amenities/AC.svg";
import Bulk_Update from "./image/Extranet_Illustrations/Bulk_Update.png";
import Access_Denied from "./image/Extranet_Illustrations/Access_Denied.png";
import finance_Dashboard from "./image/Extranet_Illustrations/finance_Dashboard.png";
import Hotel_Profile from "./image/Extranet_Illustrations/Hotel_Profile.png";
import Hotel_Summary from "./image/Extranet_Illustrations/Hotel_Summary.png";
import Inventory from "./image/Extranet_Illustrations/Inventory.png";
import Maintenance from "./image/Extranet_Illustrations/Maintenance.png";
import No_review from "./image/Extranet_Illustrations/No_review.png";
import No_bookings from "./image/Extranet_Illustrations/No_bookings.png";
import server_500 from "./image/Extranet_Illustrations/500_Server.png";
import Increase_Revenue from "./image/Extranet_Illustrations/Increase_Revenue.png";
import manage_rates from "./image/Extranet_Illustrations/manage_rates.png";
import Mail_us from "./image/Extranet_Illustrations/Mail_us.png";
import Contact from "./image/Extranet_Illustrations/Contact.png";
import Brevistay_Assist_Mobile from "./image/Extranet_Illustrations/Brevistay_Assist_Mobile.png";
import FAQs from "./image/Extranet_Illustrations/FAQs.png";
import getting_started from "./image/Extranet_Illustrations/getting_started.png";
import bookingImages from "./image/Getting Started Guide/Bookings Image.png";
import bulkUpdateImage from "./image/Getting Started Guide/Bulk update Image.png";
import downloadVoucherImage from "./image/Getting Started Guide/Download voucher image.png";
import financesImage from "./image/Getting Started Guide/Finances Image.png";
import generalNavigation from "./image/Getting Started Guide/General navigation.png";
import searchBookings from "./image/Getting Started Guide/Search Bookings.png";
import updateCheckinImage from "./image/Getting Started Guide/Update checkin Image.png";
import updateRoomsImage from "./image/Getting Started Guide/Update Rooms image.png";
import soldOutHome from "./image/other/Soldout Icon.svg";
import blockedIcon from "./image/other/Blocked Icon.svg";
// import getting_started from './image/Extranet_Illustrations/getting_started.png'
import pageNotFound from "./image/other/PageNotFound.jpg";
import internalServerErr from "./image/other/500 Server.png";
import ErrorPage from "./image/other/Error fetching.png";
import quickSoldOut from "./image/other/Quick Soldout.svg";
import Fetch_error from "./image/Extranet_Illustrations/Error fetching.png";
import AccessDenied from "./image/other/AccessDenied.png"
import errorIllustration from "./image/other/ErrorIllustration.png";
import successIllustration from "./image/other/SuccessIllustration.png";
import RoomIcon from "./image/Fullday inventory/Room Icon.png";
import RightArrow from "./image/Fullday inventory/Right_Arrow.svg";
import LeftArrow from "./image/Fullday inventory/Left_Arrow.svg";
import PropertyIcon from "./image/Fullday inventory/Property Icon.png";
import downSideArrow from "./image/Fullday inventory/down_Arrow.svg";
import blackUpArrow from "./image/Fullday inventory/blackUpArrow.svg";
import blackDownArrow from "./image/Fullday inventory/blackDownArrow.svg";

import view from "./image/other/View.svg"
import bedLogo from "./image/roomCategoryRatePlan/image301.png";
import roomImage from "./image/roomCategoryRatePlan/image314.png";
import ImagePlaceholder from "./image/roomCategoryRatePlan/Image placeholder.png";
import Confirmation from "./image/roomCategoryRatePlan/confirmation.svg";
import CreateRoomIcon from "./image/roomCategoryRatePlan/Create_Room.svg";
import blackCross from "./image/roomCategoryRatePlan/close.svg";
import breviLogo from "./image/holidaInventory/breviLogo.svg";
import holidaLogo from "./image/holidaInventory/holidaLogo.svg";
import RightArrowInventory from "./image/holidaInventory/RightArrow.svg";
import holidaSearchIcon from "./image/holidaInventory/searchIcon.svg";
import calendarLeft from "./image/holidaInventory/calendarLeft.svg";
import calendarRight from "./image/holidaInventory/calendarRight.svg";
import disableCalendarLeft from "./image/holidaInventory/disableCalendarLeft.svg";
import HIDownArrow from "./image/holidaInventory/HIDownArrow.svg";
import HIUpArrow from "./image/holidaInventory/HIUpArrow.svg";
import HIBlackCross from "./image/holidaInventory/HIBlackCross.svg";
import HIBackButton from "./image/holidaInventory/BackButton.svg";
import bulkBackIcon from "./image/holidaInventory/bulkBackIcon.svg";
import BreviInventoryBack from "./image/holidaInventory/BreviInventoryBack.svg"
import roomCategory from "./image/other/roomCategory.svg"
import noResultsImg from "./image/other/NoResults.png"
import newLogo from "./image/UpdatedExtranet/updatedHeader/newLogo.svg";
import SBUpArrow from "./image/UpdatedExtranet/SidebarIcons/SBUpArrow.svg";
import SBDownArrow from "./image/UpdatedExtranet/SidebarIcons/SBDownArrow.svg";
import SBSummaryIcon from "./image/UpdatedExtranet/SidebarIcons/SBSummaryIcon.svg";
import SBBookingIcon from "./image/UpdatedExtranet/SidebarIcons/SBBookingIcon.svg";
import SBInventoryIcon from "./image/UpdatedExtranet/SidebarIcons/SBInventoryIcon.svg";
import SBFinanceIcon from "./image/UpdatedExtranet/SidebarIcons/SBFinanceIcon.svg";
import SBProfileIcon from "./image/UpdatedExtranet/SidebarIcons/SBProfileIcon.svg";
import SBAmenityIcon from "./image/UpdatedExtranet/SidebarIcons/SBAmenityIcon.svg";
import SBStaffIcon from "./image/UpdatedExtranet/SidebarIcons/SBStaffIcon.svg";
import SBPolicyIcon from "./image/UpdatedExtranet/SidebarIcons/SBPolicyIcon.svg";
import SBPropertyIcon from "./image/UpdatedExtranet/SidebarIcons/SBPropertyIcon.svg";
import goBackIcon from "./image/UpdatedExtranet/bookingIcon/goBackIcon.svg";
import ServerError from "./image/other/ServerError.png";
import ApiError from "./image/other/ApiError.png";
import NoFilter from "./image/other/NoFilter.png";
import NoPropertyLinked from "./image/UpdatedExtranet/Login/NoPropertyLinked.png";
import CopyIcon from "./image/UpdatedExtranet/bookingIcon/CopyIcon.svg";
import rpTooltipInfoIcon from "./image/RefundPolicy/toolTioInfoIcon.svg";
import RPGreenArrow from "./image/RefundPolicy/RPGreen.svg";
import RPRedArrow from "./image/RefundPolicy/RPRed.svg";
import RPGreyArrow from "./image/RefundPolicy/RPGrey.svg";
import ratesIcon from "./image/manageRooms/Rates.svg";
import checkInIcon from "./image/manageRooms/Check-in.svg";
import soldOutIcon from "./image/manageRooms/Sold-out.svg";


const newExtranetImages = {
  soldOutIcon,
  ratesIcon,
  checkInIcon,
  RPGreenArrow,
  RPGreyArrow,
  RPRedArrow,
  rpTooltipInfoIcon,
  CopyIcon,
  NoPropertyLinked,
  NoFilter,
  ApiError,
  ServerError,
  goBackIcon,
  SBPropertyIcon,
  SBPolicyIcon,
  SBProfileIcon,
  SBStaffIcon,
  SBAmenityIcon,
  SBFinanceIcon,
  SBInventoryIcon,
  SBBookingIcon,
  SBSummaryIcon,
  SBDownArrow,
  SBUpArrow,
  newLogo,
  noResultsImg,
  roomCategory,
  BreviInventoryBack,
  bulkBackIcon,
  HIBackButton,
  HIBlackCross,
  HIDownArrow,
  HIUpArrow,
  disableCalendarLeft,
  calendarLeft,
  calendarRight,
  holidaSearchIcon,
RightArrowInventory,
  breviLogo,
  holidaLogo,
  blackCross,
  ImagePlaceholder,
  Confirmation,
  CreateRoomIcon,
  bedLogo,
  roomImage,
  errorIllustration,
  successIllustration,
  RoomIcon,
  RightArrow,
  LeftArrow,
  PropertyIcon,
  downSideArrow,
  blackUpArrow,
  blackDownArrow,
  AccessDenied,
  view,
  soldOutHome,
  AccessDenied,
  quickSoldOut,
  blockedIcon,
  ErrorPage,
  bookingImages,
  bulkUpdateImage,
  downloadVoucherImage,
  financesImage,
  searchBookings,
  updateCheckinImage,
  updateRoomsImage,
  generalNavigation,
  Access_Denied,
  finance_Dashboard,
  Hotel_Profile,
  Hotel_Summary,
  Inventory,
  Maintenance,
  No_review,
  No_bookings,
  manage_rates,
  Increase_Revenue,
  Mail_us,
  Contact,
  Brevistay_Assist_Mobile,
  FAQs,
  getting_started,
  customerCare,
  accessDenied,
  bulkUpdate,
  hotelSummary,
  financeDashboard,
  New_Hotel_Profile_blue,
  New_Hotel_Profile,
  hsassitIcon,
  hsFAQs,
  booking_m,
  finances_m,
  left,
  right,
  summary_m,
  profile_m,
  rooms_m,
  navbar_m,
  hscontactUs,
  checkInGuide,
  bookingsTab,
  bulkButton,
  editGuide,
  financeGuide,
  manageCheckInGuide,
  inventoryDatesGuide,
  bookingsSearch,
  bulkUpdateDates,
  up,
  down,
  up_black,
  down_black,
  left_black,
  right_black,
  error,
  hsgettingStarted,
  available_rooms,
  sold_out_Rooms,
  extranet_logo,
  hslimitedAccess,
  commision,
  total_sales,
  server_500,
  hsgettingStarted,
  greenup,
  hslimitedAccess,
  internalServerErr,
  pageNotFound,
  hsmailUs,
  hsmanageRates,
  newPagehotelProfile,
  increaseRevenue,
  inventory,
  manageRates,
  noBooking,
  filter,
  tipIcon,
  totalRooms,
  totalRoomsSold,
  editDraft,
  transferredIn,
  Edit,
  transferredOut,
  Loader,
  deleteDraft,
  notification,
  downArrow,
  upArrow,
  // poll,
  redArrow,
  saved,
  greenArrow,
  property,
  unlinked,
  inactive,
  noReview,
  active,
  linked,
  luxury,
  newIcon,
  Premium,
  // downloadIcon,
  hotelImage,
  closeRedIcon,
  verifiedIcon,
  checkAll,
  rupeeIcon,
  arrowIcon,
  notesIcon,
  calenderIcon,
  ledgerIcon,
  invoiceIcon,
  salesIcon,
  summary,
  bookingHistoryIcon,
  editRooms,
  downloadIcon,
  upcomingBookingsIcon,
  home,
  bookings,
  closeBlack,
  amenities,
  admin,
  star,
  save,
  closePurple,
  hotelFinance,
  hotelProfile,
  hotelPolicies,
  manageRooms,
  staff,
  banquet_hall: banquet_hall,
  bar,
  AC,
  bathtub,
  breakfast,
  ExtranetLogo,
  profileOpen,
  profileUnopen,
  gym,
  CCTV,
  conference_room,
  credit_debit,
  dining_area,
  elevator,
  fridge,
  Geyser,
  Jacuzzi,
  Gold,
  Silver,
  Bronze,
  Laundry,
  Parking,
  Pet,
  power_backup,
  restaurant,
  spa,
  Swimming,
  TV,
  Water,
  wheelchairs,
  Wifi,
  viewRooms,
  am_banquet_hall,
  am_bar,
  am_ac,
  am_bathtub,
  am_breakfast,
  // ExtranetLogo,
  // profileOpen,
  // profileUnopen,
  am_gym,
  am_public_cctv,
  conference_room,
  am_cards_accepted,
  am_dining_area,
  am_elevator,
  am_fridge,
  am_geyser,
  am_jacuzzi,
  // Gold,
  // Silver,
  // Bronze,
  am_laundry,
  am_parking,
  Pet,
  am_power_backup,
  am_house_restaurant,
  am_spa,
  am_swimming,
  am_tv,
  am_water,
  am_wheelchair,
  am_wifi,
  Notification,
  Stars,
  Loader,
  Total_Rooms,
  Total_Rooms_Sold,
  Blocked_Icon,
  Soldout_Icon,
  Bulk_Update,
  close,
  Fetch_error
};

export default newExtranetImages;
