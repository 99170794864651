import { TYPE } from '../actions/types'
const intialState = {
    roomsApiRes: {},
    roomsApiLoader: true,
    hotelRoomsData: {},
    updateRoomResponse: {},
    specificHotelRoomsData: {},
}

export default function (state = intialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_HOTEL_ROOMS:
            state.roomsApiRes = response
            state.hotelRoomsData = action.payload;
            state.roomsApiLoader = false
            return { ...state, response: action.payload }
        case TYPE.UPDATE_HOTEL_ROOMS:
            state.updateRoomResponse = action.payload;
            return { ...state, response: action.payload }
        case "clear_rooms_api_res":
            state.roomsApiLoader = true
            state.roomsApiRes = {}
            state.hotelRoomsData = {};
            return {...state}
        case "clear_update_room_response":
            state.updateRoomResponse = {};
            return { ...state, response: action.payload };
        case "clear_hotel_rooms_data":
            state.hotelRoomsData = {};
            state.roomsApiLoader = true
            state.roomsApiRes = {}
            return { ...state, response: action.payload };
             
        default:
            return state;
    }
}

