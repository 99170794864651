import { TYPE } from '../actions/types';
const intialState = {
    hotelRates: {},
    updateRateResponse: {},
};


export default function (state = intialState, action) {
    switch (action.type) {
        case TYPE.GET_HOTEL_RATES:
            state.hotelRates = action.payload;
            return { ...state, response: action.payload }
        case TYPE.UPDATE_HOTEL_RATES:
             state.updateRateResponse = action.payload;
            return { ...state, response: action.payload }
        case "clear_update_rate_response":
            state.updateRateResponse = {};
            return { ...state, response: action.payload };
        case "clear_hotel_rates":
            state.hotelRates = {};
            return { ...state, response: action.payload };
            
        default:
            return state;
    }
}

