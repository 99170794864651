import { TYPE } from '../actions/types'
const intialState = {
    soldOutDatesRes: {},
    soldOutDatesApiLoader: true,
    holdSoldOutRes: {},
    updateRemoveDateRes: {},
    updateUnsoldDateRes:{},
    updateAddDateRes: {},
    specificDateSoldOutRes: {},
}

export default function (state = intialState, action, response = action.payload) {
    switch (action.type) {
        case TYPE.GET_SOLD_OUT_DATES:
            state.soldOutDatesRes = response
            state.soldOutDatesApiLoader = false
            state.holdSoldOutRes = action.payload;
            return { ...state, response: action.payload }
        case TYPE.UPDATE_SOLD_OUT_DATE:
            state.updateRemoveDateRes = action.payload;
            return { ...state, response: action.payload }
        case TYPE.HOTEL_SOLD_OUT:
            state.updateAddDateRes = action.payload;
            return { ...state, response: action.payload }
        case TYPE.HOTEL_BULK_UNSOLD_OUT:
            state.updateUnsoldDateRes=action.payload;
            return { ...state, response: action.payload }
        case "clear_soldOut_res":
            state.soldOutDatesApiLoader = true
            state.soldOutDatesRes = {}
            state.holdSoldOutRes = {};
            return {...state}
        case "clear_update_remove_date_res":
            state.updateRemoveDateRes = {};
            return { ...state, response: action.payload };
        case "clear_update_add_date_res":
            state.updateAddDateRes = {};
            return { ...state, response: action.payload };
        case "clear_update_unsold_date_res":
            state.updateUnsoldDateRes = {};
            return { ...state, response: action.payload };
        case "clear_hold_sold_out_res":
            state.holdSoldOutRes = {};
            state.soldOutDatesRes = response
            state.soldOutDatesApiLoader = false
            return { ...state, response: action.payload };
        default:
            return state;
    }
}

